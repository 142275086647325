<template>
  <div class="ranking-page">
    <p class="ranking-title">{{ title }}</p>
    <el-tabs type="border-card" class="ranking-tabs">
      <el-tab-pane label="天亮总榜" class="ranking-pane">
        <div v-for="(item, index) in tlRanking" :key="index" class="item">
          <div class="item-rank">
            <img v-if="index === 0" src="../../../assets/images/typing_1.png" class="rank-img">
            <img v-else-if="index === 1" src="../../../assets/images/typing_2.png" class="rank-img">
            <img v-else-if="index === 2" src="../../../assets/images/typing_3.png" class="rank-img">
            <span v-else>{{ index + 1 }}</span>
          </div>
          <!--          <el-avatar size="small" src="../../../assets/images/logo.png" :class="{ 'item-avatar_1': index < 3, 'item-avatar_2': index > 2 }"></el-avatar>-->
          <div class="item-avatar">
            <div class="user-avatar-box">
              <img :src="item.stuAvatar === null ? $pic : $downloadUrl + item.stuAvatar" class="user-avatar">
            </div>
          </div>
          <div class="item-user">{{ item.createBy === null ? '--' : dealName(item.createBy) }}</div>
          <div class="item-speed">{{ item.speed === null ? '--' : item.speed }}<span class="speed-unit">{{ unit }}</span></div>
          <div class="item-time">{{ item.createTime === null ? '-- - -- -- : --' : parseTime(item.createTime, '{m} - {d} {h} : {i}') }}</div>
        </div>
        <div class="ranking-wait-tl">
          <img v-if="tlRanking.length <= 3" src="../../../assets/images/typing_wait_2.png" alt="" class="ranking-wait-img">
        </div>
      </el-tab-pane>
      <el-tab-pane label="班级总榜" class="ranking-pane">
        <div v-for="(item, index) in classRanking" :key="index" class="item">
          <div class="item-rank">
            <img v-if="index === 0" src="../../../assets/images/typing_1.png" class="rank-img">
            <img v-else-if="index === 1" src="../../../assets/images/typing_2.png" class="rank-img">
            <img v-else-if="index === 2" src="../../../assets/images/typing_3.png" class="rank-img">
            <span v-else>{{ index + 1 }}</span>
          </div>
          <!--          <el-avatar size="small" src="../../../assets/images/logo.png" :class="{ 'item-avatar_1': index < 3, 'item-avatar_2': index > 2 }"></el-avatar>-->
          <div class="item-avatar">
            <div class="user-avatar-box">
              <img :src="item.stuAvatar === null ? $pic : $downloadUrl + item.stuAvatar" class="user-avatar">
            </div>
          </div>
          <div class="item-user">{{ item.createBy === null ? '--' : dealName(item.createBy) }}</div>
          <div class="item-speed">{{ item.speed === null ? '--' : item.speed }}<span class="speed-unit">{{ unit }}</span></div>
          <div class="item-time">{{ item.createTime === null ? '-- - -- -- : --' : parseTime(item.createTime, '{m} - {d} {h} : {i}') }}</div>
        </div>
        <div class="ranking-wait-class">
          <img v-if="classRanking.length <= 3" src="../../../assets/images/typing_wait_2.png" alt="" class="ranking-wait-img">
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { listRecordRanking } from '@/api/typing/record'

export default {
  name: 'TypingRanking',
  components: {},
  props: {
    chEn: {
      type: Boolean,
      default: true
    },
    showMore: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    showMore() {
      this.getRankingList()
    }
  },
  created() {
    this.title = this.chEn ? '中文排行榜' : '英文排行榜'
    this.unit = this.chEn ? 'WPM' : 'KPM'
    this.getRankingList()
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo
    })
  },
  data() {
    return {
      title: '',
      unit: '',
      num: 10,
      tlRanking: [],
      classRanking: [],
      queryParams: {
        articleLang: '0',
        recordNum: 10
      }
    }
  },
  methods: {
    getRankingList() {
      this.queryParams.recordNum = this.showMore ? 100 : 10
      this.queryParams.articleLang = this.chEn ? '0' : '1'
      // const tempObj = {
      //   stuAvatar: null,
      //   createBy: null,
      //   speed: null,
      //   createTime: null
      // }
      listRecordRanking({
        ...this.queryParams,
        isGeneralList: true
      }).then(response => {
        this.tlRanking = response.data
        // const num = this.queryParams.recordNum - this.tlRanking.length
        // if (this.tlRanking.length === 0) {
        //   for (let i = 0; i < 3; i++) {
        //     this.tlRanking.push(tempObj)
        //   }
        // }
      })
      listRecordRanking({
        ...this.queryParams,
        isGeneralList: false
      }).then(response => {
        this.classRanking = response.data
        // const num = this.queryParams.recordNum - this.classRanking.length
        // if (this.classRanking.length === 0) {
        //   for (let i = 0; i < 2; i++) {
        //     this.classRanking.push(tempObj)
        //   }
        // }
      })
    },
    dealName(val) {
      let re = ''
      if (val && val !== '') {
        re = val.length > 6 ? val.substr(0, 6) + '...' : val
      }
      return re
    }
  }
}
</script>

<style scoped lang="less">
.ranking-page {
  font-family: "宋体" !important;
  .ranking-title {
    line-height: 40px;
    height: 40px;
    color: #fff;
  }
  .ranking-tabs {
    border-radius: 0 0 10px 10px;
    .ranking-pane {
      min-height: 365px;
      //display: flex;
      //flex-flow: column nowrap;
      //
      //.ranking-wait-el,
      //.ranking-wait-class {
      //  flex: 1;
      //}
      .ranking-wait-img {
        width: 257px;
      }
    }

    .item {
      width: 100%;
      height: 35px;
      text-align: left;
      //background-color: #ccc;
      border-bottom: 1px solid #ccc;
      //margin: 5px 0;
      overflow: hidden;
      .item-rank,.item-avatar,.item-user,.item-speed,.item-time {
        float: left;
        margin: 0 3px;
      }

      .item-rank {
        width: 35px;
        height: 35px;
        text-align: center;
        line-height: 35px;
        .rank-img {
          width: 90%;
          height: 90%;
          margin: 5%;
        }
      }
      .item-avatar {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        .user-avatar-box {
          width: 86%;
          height: 82%;
          background: #4c97fd;
          margin: 9% 7%;
          border-radius: 50%;
        }
        .user-avatar {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .item-user {
        width: 200px;
        height: 35px;
        font-size: 16px;
        line-height: 35px;
      }
      .item-speed {
        width: 120px;
        height: 35px;
        font-size: 16px;
        line-height: 35px;
        .speed-unit {
          font-size: 12px;
          color: #cccccc;
        }
      }
      .item-time {
        width: 120px;
        height: 35px;
        font-size: 16px;
        line-height: 35px;
      }
    }
  }
  ::v-deep .ranking-tabs .el-tabs__nav {
    width: 100%;
  }
  ::v-deep .ranking-tabs .el-tabs__item {
    width: 50%;
  }
  ::v-deep .el-tabs--border-card {
    box-shadow: none;
  }
}
</style>

<template>
  <div class="practice-page">
    <div class="box">
      <div class="typing-setting">
        <div class="setting-form">
          <p class="form-title">在线打字测评</p>
          <el-form
            ref="settingForm"
            :inline="true"
            :model="settingParams"
            :rules="rules"
            class="form"
            label-width="68px"
          >
            <el-row>
              <el-col :span="24">
                <el-form-item label="用户名称">
                  <el-input
                    v-model="userInfo.name"
                    :readonly="true"
                    size="small"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="文章类型" prop="articleType">
                  <el-radio-group v-model="settingParams.articleType" size="small" @change="handleType">
                    <el-radio-button
                      v-for="dict in articleType"
                      :key="dict.value"
                      :label="dict.value"
                    >{{ dict.label }}
                    </el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="文章语言" prop="articleLang">
                  <el-radio-group v-model="settingParams.articleLang" size="small" @change="handleLang">
                    <el-radio-button
                      v-for="dict in articleLang"
                      :key="dict.value"
                      :label="dict.value"
                    >{{ dict.label }}
                    </el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="选择文章" prop="articleId">
                  <el-select
                    v-model="settingParams.articleId"
                    placeholder="请选择文章"
                    clearable
                    size="small"
                    popper-class="article-list-popper"
                    :popper-append-to-body="false"
                  >
                    <el-option
                      v-for="item in articleList"
                      :key="item.id"
                      :label="item.title"
                      :value="item.id"
                    >
                      <span style="float: left" class="test">{{ item.title }}</span>
                    </el-option>
                  </el-select>
                  <el-button type="text" size="small" class="random-btn" @click="randomSelect(-1)">随机选</el-button>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24" class="setting-time-col">
                <el-form-item label="测试时间" prop="setTime">
                  <el-input
                    v-model="settingParams.setTime"
                    placeholder="请输入测试时间"
                    size="small"
                    style="width: 125px;"
                  />
                  <span class="setting-time-span">分钟（范围值1~50）</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24" class="setting-btn-col">
                <el-button type="primary" class="setting-btn" @click="handleTest">立即测试</el-button>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <img
          alt="打字记录"
          class="typing-setting-record"
          src="../../assets/images/typingRecord.png"
          @click="openRecordEvent"
        >
      </div>
      <div class="ranking-info">
        <p>
          <img src="../../assets/images/typing_ranking.png" class="ranking-img">
          <span>排名条件</span>
          正确率≥95%，设定时间≥3分钟的系统文章测试参与排名，结果按速度排序，显示近24小时成绩排名！
        </p>
      </div>
      <div class="typing-ranking">
        <ranking
          class="ranking-ch"
          :ch-en="true"
          :show-more="showMore"
        />
        <ranking
          class="ranking-en"
          :ch-en="false"
          :show-more="showMore"
        />
      </div>
      <el-button
        type="text"
        @click="switchRankingList"
      >
        {{ btnText }}
      </el-button>

    </div>
    <typing-record :open.sync="openRecord" />
    <index-bottom />
  </div>

</template>

<script>
import indexBottom from '../../components/indexBottom'
import ranking from './components/ranking'
import { mapState } from 'vuex'

import { listArticle } from '@/api/typing/article'
import TypingRecord from '@/views/typing/components/TypingRecord.vue'
// getArticle
export default {
  name: 'PracticePage',
  components: {
    indexBottom,
    ranking,
    TypingRecord
  },
  created() {
    this.reset()
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
      articleType: state => state.dict.dictTree.article_type['arr'],
      articleLang: state => state.dict.dictTree.article_lang['arr']
    })
  },
  data() {
    return {
      openRecord: false,
      // 打字参数
      settingParams: {},
      // 文章列表
      articleList: [],
      showMore: false,
      btnText: '查看更多',
      rules: {
        articleType: [
          { required: true, message: '请选择文章类型', trigger: 'change' }
        ],
        articleLang: [
          { required: true, message: '请选择文章语言', trigger: 'change' }
        ],
        articleId: [
          { required: true, message: '请选择一篇文章', trigger: 'change' }
        ],
        setTime: [
          { required: true, message: '测试时间不能为空', trigger: 'change' },
          { validator: this.handleSetTime }
        ]
      }
    }
  },
  methods: {
    openRecordEvent() {
      this.openRecord = true
    },
    reset() {
      this.settingParams = {
        articleType: '0',
        articleLang: '1',
        articleId: null,
        setTime: 5
      }
      this.getArticleList()
    },
    getArticleList() {
      listArticle({
        type: this.settingParams.articleType,
        lang: this.settingParams.articleLang
      }).then(response => {
        this.articleList = response.rows
        if (Array.isArray(this.articleList) && this.articleList.length !== 0) {
          this.randomSelect(0)
        } else {
          this.settingParams.articleId = null
        }
      })
    },
    handleType(val) {
      const lang = this.settingParams.articleLang
      if (lang !== null && lang !== '') {
        if (lang === '0' && val === '0') {
          this.$message.warning('实战代码中没有中文文章')
          this.settingParams.articleLang = '1'
          this.getArticleList()
        } else {
          this.getArticleList()
        }
      }
    },
    handleLang(val) {
      const type = this.settingParams.articleType
      if (type !== null && type !== '') {
        if (type === '0' && val === '0') {
          this.$message.warning('实战代码中没有中文文章')
          this.settingParams.articleLang = '1'
          this.getArticleList()
        } else {
          this.getArticleList()
        }
      }
    },
    randomSelect(val) {
      console.log('old：' + val)
      if (Array.isArray(this.articleList) && this.articleList.length !== 0) {
        if (val && val < 0) {
          val = Math.round(Math.random() * (this.articleList.length - 1))
        }
        console.log('new：' + val)
        this.settingParams.articleId = this.articleList[val].id
      }
    },
    handleTest() {
      this.$refs['settingForm'].validate(valid => {
        if (valid) {
          const article = {
            ...this.settingParams
          }
          const record = {
            totalChar: 0, // 输入字符总数
            correctChar: 0, // 正确字符个数
            errorChar: 0, // 错误字符个数
            backspace: 0 // 退格键次数
          }
          this.$store.commit('SET_ARTICLE', article)
          this.$store.commit('SET_RECORD', record)

          this.$router.push({
            path: '/typingStart',
            query: {
              id: this.settingParams.articleId
            }
          })
        }
      })
    },
    switchRankingList() {
      this.showMore = !this.showMore
      this.btnText = this.showMore ? '收起' : '查看更多'
    },
    handleSetTime(rule, value, callback) {
      const numberReg = /^[0-9.]+$/
      if (!numberReg.test(value)) {
        callback(new Error('请输入数字'))
      }
      const point = /[.]/g
      // const pointArr = value.match(point)
      // if (Array.isArray(pointArr) && pointArr.length > 0) {
      //   callback(new Error('请输入整数'))
      // }
      if (point.test(value)) {
        callback(new Error('请输入整数'))
      }
      if (value < 1 || value > 50) {
        callback(new Error('范围值1~50'))
      }
      callback()
    }
  }
}
</script>

<style scoped lang="less">
.practice-page {
  margin-top: 80px;

  .box {
    width: 1200px;
    margin: 0 auto;

    .typing-setting {
      //padding: 5px 315px;
      position: relative;

      &-record {
        position: absolute;
        top: 184px;
        right: 80px;
        cursor: pointer;
      }

      .setting-form {
        width: 100%;
        height: 650px;
        //background-color: pink;
        border-radius: 10px;
        background-image: url("../../assets/images/typing_form.png");
        background-size: cover;
        background-repeat: no-repeat;
        padding: 85px 325px;

        p {
          font-size: 32px;
          color: #4392fd;
        }

        .form {
          text-align: left;
          padding: 30px 100px 0 100px;

          .setting-time-span {
            margin: 0 15px;
            color: #ccc;
            font-size: 12px;
          }

          .setting-btn-col {
            text-align: center;

            .setting-btn {
              width: 140px;
            }
          }

          .random-btn {
            margin-left: 10px;
          }

          .article-list-popper .test {
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
          }

          ::v-deep .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
            display: none;
          }
        }
      }
    }

    .ranking-info {
      //padding: 5px 315px;
      margin: 20px 0;

      p {
        width: 100%;
        height: 40px;
        line-height: 40px;
        background-color: #fff;
        display: inline-block;
        border-radius: 10px;
        text-align: left;
        padding: 0 5px;
        font-size: 12px;

        span {
          color: #5c94e0;
          font-weight: 600;
          font-size: 16px;
          margin-right: 20px;
        }
      }

      .ranking-img {
        margin: -4px 5px;
      }
    }

    .typing-ranking {
      //padding: 5px 315px;
      overflow: hidden;

      .ranking-ch {
        width: 49%;
        background-color: #5c94e0;
        border-radius: 10px;
        float: left;
      }

      .ranking-en {
        width: 49%;
        background-color: #5c94e0;
        border-radius: 10px;
        float: right;
      }
    }

    ::v-deep .article-list-popper .el-select-dropdown__list {
      width: 500px;
      max-height: 150px;
      //overflow-y: scroll;
      overflow-x: hidden;
      padding: 5px;
    }

    ::v-deep .article-list-popper .el-select-dropdown__item {
      min-width: 150px;
      max-width: 200px;
      float: left;
      border: 1px solid #ccc;
      border-radius: 5px;
      margin: 5px;

    }

    ::v-deep .article-list-popper .el-select-dropdown__item.selected {
      border-color: #409EFF;

    }
  }

}
</style>

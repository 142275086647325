<template>
  <div class="typingRecord">
    <el-dialog
      :before-close="close"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="dialogVisible"
      center
      title="打字记录"
      top="100px"
      width="582px"
    >
      <ul v-if="record.length" class="record">
        <li v-for="i in record" :key="i.id" class="record-item">
          <div class="record-item__body">
            <div class="record-item__info">
              <span class="record-item__portrait">{{ getUserName() }}</span>
              <span class="record-item__title">{{ i.articleTitle }}</span>
            </div>
            <div class="record-item__time">{{ i.createTime }}</div>
          </div>
          <div class="record-item__footer">
            <p class="record-item__footer-item">
              <img alt="实战代码" src="../../../assets/images/fenlei.png">
              <span>{{ articleType[i.articleType] }}</span>
            </p>
            <p class="record-item__footer-item">
              <img alt="汉译英" src="../../../assets/images/fanyi.png">
              <span>{{ articleLang[i.articleLang] }}</span>
            </p>
            <p class="record-item__footer-item">
              <img alt="min" src="../../../assets/images/shijian.png">
              <span>设定<span style="color: #409EFF">{{ i.setTime }}</span>min</span>
            </p>
            <p class="record-item__footer-item">
              <img alt="kpm" src="../../../assets/images/sudu.png">
              <span><span style="color: #409EFF">{{ i.speed }}</span>kpm</span>
            </p>
          </div>
        </li>
      </ul>
      <el-empty
        v-else
        :image="require('@/assets/images/empty.png')"
        class="none-box"
        description="暂无数据"
        style="min-height: 500px"
      />
      <span slot="footer" class="dialog-footer">
        <el-button round type="primary" @click="close">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getRecordList } from '@/api/typing/article'

export default {
  name: 'TypingRecord',
  props: {
    open: Boolean
  },
  data() {
    return {
      dialogVisible: false,
      record: []
    }
  },
  watch: {
    open(val) {
      this.dialogVisible = val
      val && this.getList()
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
      articleType: state => state.dict.dictTree.article_type['obj'],
      articleLang: state => state.dict.dictTree.article_lang['obj']
    })
  },
  methods: {
    getUserName() {
      const name = this.userInfo.name || ''
      return name.charAt(name.length - 1)
    },
    close() {
      this.$emit('update:open', false)
    },
    getList() {
      getRecordList({
        id: this.userInfo.id
      }).then(res => {
        this.record = res.data
      })
    }
  }
}
</script>

<style lang="less" scoped>
.typingRecord {
  /*里面的代码可以根据自己需求去进行更改*/
  /* 设置滚动条的样式 */

  ::-webkit-scrollbar {
    width: 6px;
  }

  /* 滚动条滑块 */

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #E6E6E6;
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
  }

  ::v-deep .el-dialog {
    height: 699px;
    background: #FFFFFF;

    &__header {
      border-bottom: 1px solid #E7ECF2;
    }

    &__title {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #409EFF;
    }

    &__body {
      height: 570px;
      padding: 10px;
    }
  }

  .record {
    height: 100%;
    overflow: auto;
    padding: 10px;

    &-item {
      width: 100%;
      height: 121px;
      background: #FFFFFF;
      box-shadow: 0 0 10px 0 rgba(64, 64, 64, 0.15);
      margin-bottom: 12px;
      padding: 0 16px;
      border-radius: 4px;

      &__body {
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &__info {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        display: flex;
        align-items: center;
        width: calc(100% - 150px);

      }

      &__title {
        white-space: nowrap; //禁止换行
        overflow: hidden; //超出隐藏
        text-overflow: ellipsis; //显示省略号
        width: 80%;
      }

      &__portrait {
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        margin-right: 6px;
        background: #6AB3FF;
        border-radius: 50%;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        flex-shrink: 0;
      }

      &__time {
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #9099B7;
        flex-shrink: 0;
        width: 150px;
        text-align: right;
      }

      &__footer {
        height: 50px;
        border-top: 1px solid #EFF2F8;
        display: flex;
        align-items: center;

        &-item {
          display: flex;
          align-items: center;
          margin-right: 20px;

          span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #8A94B3;
            margin-left: 4px;
          }
        }
      }
    }
  }
}
</style>
